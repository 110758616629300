import request from '../utils/request'

//个人信息资料填写
export function getRegister(params) {
  return request.post('/api/user/register', params)
}

//学校信息
export function getSchoolInfo() {
  return request.get('/api/index/schoolInfo')
}

//授权之后获取微信用户信息
export function getInfo(params) {
  return request.post('/api/user/wxMiniProgramLoginSilent', params)
}

//模拟练习
export function getExercise() {
  return request.get('/api/exam/exercise')
}

//正式答题(题目列表)
export function getStartExam(params) {
  return request.get('/api/exam/startExam', params)
}

//正式答题时间
export function getTime(params) {
  return request.post('/api/exam/getStartTime', { params })
}

//模拟答题
export function getExerciseAnswer(params) {
  return request.post('/api/exam/exerciseAnswer', params)
}

//答题
export function getAnswer(params) {
  return request.post('/api/exam/answer', params)
}
//学校教师排名
export function schoolteacherRank(params) {
  return request.post('/api/exam/schoolteacherRank', params)
}
//学校家长排名
export function schoolParentRank(params) {
  return request.post('/api/exam/schoolParentRank', params)
}
//家长排名
export function parentRank(params) {
  return request.post('/api/exam/parentRank', params)
}
//老师排名
export function teacherRank(params) {
  return request.post('/api/exam/teacherRank', params)
}
//查排名--验证用户信息
export function checkUser(params) {
  return request.post('/api/user/checkUser', params)
}



//查排名方式登录
export function getLogin(params) {
  return request.post('/api/user/login', params)
}

//人数
export function getNum(params) {
  return request.post('/api/index/getRegisterCount', params)
}

//奖状
export function uploadData(params) {
  return request.post('/api/exam/getAwardImage', params)
}

//更新信息
export function profileData(params) {
  return request.post('/api/user/profile', params)
}
//领取奖品
export function receivePrizes(params) {
  return request.post('/api/user/receivePrizes',params)
}
//领取奖状
export function diploma(params) {
  return request.post('/api/user/diploma',params)
}
export const bgimgUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/background2.png?num=${(Math.random()*10).toFixed(1)}`;	//  首页背景
export const bgimgUrl2 = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/bg1.png?num=${(Math.random()*10).toFixed(1)}`;      //内容也背景
export const jsBtnUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/teacher.png?num=${(Math.random()*10).toFixed(1)}`;	//教师按钮
export const jzBtnUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/parent.png?num=${(Math.random()*10).toFixed(1)}`;	//家长按钮
export const qtBtnUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/massesButton.png?num=${(Math.random()*10).toFixed(1)}`;	//其他社区按钮图
export const titleUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/hometitle1.png?num=${(Math.random()*10).toFixed(1)}`; //标题
export const textUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/datitext.png?num=${(Math.random()*10).toFixed(1)}`; //文字

export const titleUrl2 = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/pufatext.png?num=${(Math.random()*10).toFixed(1)}`;	//  答题页面标题
export const logoUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/man.png?num=${(Math.random()*10).toFixed(1)}`;      //答题页面logo图片
export const ewmUrl = `https://jiatingpufa.s3.cn-east-2.jdcloud-oss.com/jtpf_pictrue/notice.png?num=${(Math.random()*10).toFixed(1)}`;      //答题页面logo图片
